import React from 'react';
import { getFromConfig } from './localeURL'

class DynamicRS {
  resStrings: { key: string; value: string }[] | undefined

  async init() {
    this.resStrings = await this.loadRS()
  }

  async loadRS(): Promise<{ key: string; value: string }[]> {
    const isoCode = getFromConfig(
      process.env.LOCALE || `en`,
      `isoCode`,
      process.env.PROJECT || `EasyRedmineGatsby`
    )

    const path = `/puxapi/localization-json/` + isoCode + `?prefix=`
    const hostname = process.env.GATSBY_ORCHARD_API_URL
    const url = hostname + path

    try {
      const response = await fetch(url)
      const data = await response.json()
      return Array.isArray(data) ? data : []
    } catch (error) {
      console.error(`Error fetching data:`, error)
      return []
    }
  }

  getResourceString(originalRS: string): string {
    if (!this.resStrings) {
      return originalRS
    }

    const translationObj = this.resStrings.find((obj) => obj.key === originalRS)

    return translationObj?.value || originalRS
  }

  getResourceStringHTML(originalRS: string): React.ReactNode {
    if (!this.resStrings) {
      return originalRS
    }

    const translationObj = this.resStrings.find((obj) => obj.key === originalRS)

    return translationObj?.value ?
      (<span dangerouslySetInnerHTML={{ __html: translationObj?.value }} />) :
      (<span dangerouslySetInnerHTML={{ __html: originalRS }} />)

  }
}

let dynamicRSinstance: DynamicRS | null = null

export async function initDynamicRS(): Promise<void> {
  if (!dynamicRSinstance) {
    dynamicRSinstance = new DynamicRS()
    await dynamicRSinstance.init()
  }
}

export function dt(key: string): string {
  return dynamicRSinstance?.getResourceString(key) || key
}

export function dtx(key: string): React.ReactNode {
  return dynamicRSinstance?.getResourceStringHTML(key) || (<span>{key}</span>)
}
