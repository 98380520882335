import { getWidgetQuery } from "../../queries/widget-queries"
import { WidgetPuxSectionType } from "../../components/widgets/WidgetPuxSection"
import { getBuilderService } from "./puxBuilderService"
import { puxGraphqlHelper } from "./puxGraphqlHelper"
import { PageType, PageContentType } from "../../queries/page-queries"
import { getRepeaterData } from "./getRepeaterData"
import { WidgetData } from "Shared/components/builders/WidgetBuilder"
import { getFormWidgetFromFormContainer } from "./getFormWidgetFromFormContainer"

type IWidgetZoneItem<T> = { [key: string]: T[] }

export interface IGetWidgetsFromOrchardWidgetZone {
    zone: IWidgetZoneItem<WidgetPuxSectionType> | null
    baseQueryForWidget: string
    path: string
    documentType: PageContentType
    widgetZoneName: string
    isPreview?: boolean
    versionId?: string
}

export const getWidgetsFromOrchardWidgetZone = async ({
    zone,
    baseQueryForWidget,
    path,
    documentType,
    widgetZoneName = `flow`,
    isPreview = false,
    versionId = undefined,
}: IGetWidgetsFromOrchardWidgetZone): Promise<WidgetPuxSectionType[][]> => {
    const { locale } = getBuilderService()
    // change first char in string to lowercase
    const documentTypeCamelCase = documentType.charAt(0).toLowerCase() + documentType.slice(1)


    if (zone === null) {
        zone = { widgets: [] }
    }

    const dataTemplate = {}
    zone.widgets.map((section) => {
        if (section.contentItemId) {
            dataTemplate[section.contentItemId] = []
            section.flow.widgets.map((widget) => {
                if (section.contentItemId && dataTemplate[section.contentItemId].indexOf(widget.contentType) < 0) {
                    dataTemplate[section.contentItemId].push(widget.contentType)
                }
            })
        }
    })

    return Promise.all(
        Object.keys(dataTemplate).map(async (sectionID) => {
            const sectionObject = Object.assign(
                {},
                zone?.widgets.filter((sectionWidget) => {
                    return sectionWidget.contentItemId === sectionID
                })[0]
            )

            const templates = zone?.widgets.filter(
                (template) => {
                    return template?.contentItemId === sectionObject?.contentItemId
                }
            )

            sectionObject.template = templates[0].flow.widgets

            sectionObject.flow.widgets = []

            if (dataTemplate[sectionID].length) {
                if (isPreview === true) {
                    return Promise.all(
                        dataTemplate[sectionID].map(async (widgetFragment) => {
                            return new Promise(async (resolve) => {
                                const fragment = getWidgetQuery(widgetFragment, locale.preferedContentLinkCulture.toLowerCase())
                                if (fragment === ``) {
                                    return
                                }

                                const heroBannerContainerFragment = fragment.replace('%HeroBannerItem%', '')
                                let modifiedFragment
                                if (fragment.includes('Orchard_EasySoftwareHeroBannerContainer') || fragment.includes('EasySoftwareHeroBannerContainer')) {
                                    const heroBannerQuery = baseQueryForWidget
                                        .replace(/%baseSectionQueryContentType%/g, ``)
                                        .replace(/%widgetFragmentsPlaceholder%/g, heroBannerContainerFragment)
                                        .replace(/%pathCondition%/g, `(first: 1,${isPreview ? " status: LATEST," : ""} where: {path: "${path}"${versionId ? ", contentItemVersionId: \"" + versionId + "\"" : ""}})`)

                                    const heroBannerResult = await puxGraphqlHelper<PageType>(heroBannerQuery, { errorMetadata: { message: `Error while running GraphQL query for widgetFragment ${widgetFragment}` } })

                                    modifiedFragment = getHeroBannerItem(fragment, heroBannerResult)
                                }

                                const widgetQuery = baseQueryForWidget
                                    .replace(/%baseSectionQueryContentType%/g, ``)
                                    .replace(/%widgetFragmentsPlaceholder%/g, modifiedFragment ?? fragment)
                                    .replace(/%pathCondition%/g, `(first: 1,${isPreview ? " status: LATEST," : ""} where: {path: "${path}"${versionId ? ", contentItemVersionId: \"" + versionId + "\"" : ""}})`)

                                const widgetNode = await puxGraphqlHelper<PageType>(widgetQuery, {
                                    errorMetadata:
                                    {
                                        message: `Error while running GraphQL query for widgetFragment ${widgetFragment}`,
                                        metadata: `Query: ${widgetQuery}`
                                    }
                                })

                                if (widgetNode) {
                                    widgetNode[documentTypeCamelCase][0][widgetZoneName].widgets
                                        .filter((section) => section.contentItemId === sectionID)[0]
                                        .flow.widgets.filter((widget) => Object.keys(widget).length > 0)
                                        .map(async (widget: WidgetData) => {
                                            // PuxRepeater - begin
                                            if (
                                                widget.contentType === `PuxSimpleRepeater` &&
                                                widget.puxSimpleFilter
                                            ) {
                                                const repData = await getRepeaterData(widget)
                                                const repWid = { ...repData }
                                                sectionObject.flow.widgets.push(repWid as WidgetData)
                                            } else if (widget.contentType === `PuxFormContainer`) {
                                                const formId = widget.formContainerSelector.contentItemIds[0] ?? undefined
                                                if (formId) {
                                                    const formWidgetData = await getFormWidgetFromFormContainer(formId)
                                                    sectionObject.flow.widgets.push({
                                                        ...formWidgetData,
                                                        metadata: { ...widget.metadata },
                                                        puxWidgetProperties: { ...widget.puxWidgetProperties },
                                                        puxWidgetAnimation: { ...widget.puxWidgetAnimation },
                                                        contentType: widget.contentType,
                                                        contentItemId: widget.contentItemId,
                                                        mautic: {
                                                            formContainerMauticID: widget.formContainerMauticID,
                                                            formContainerSource: widget.formContainerSource,
                                                            formContainerSubmissionID: widget.formContainerSubmissionID,
                                                        }
                                                    })
                                                }
                                            } else {
                                                sectionObject.flow.widgets.push(widget)
                                            }
                                            // PuxRepeater - end
                                            // todo: resolve az za map
                                            resolve(sectionObject)
                                        })
                                } else {
                                    resolve(sectionObject)
                                }
                            })
                        })
                    )
                } else {
                    dataTemplate[sectionID].map(async (widgetFragment) => {
                        const fragment = getWidgetQuery(widgetFragment, locale.preferedContentLinkCulture.toLowerCase())
                        if (fragment === ``) {
                            return
                        }

                        const heroBannerContainerFragment = fragment.replace('%HeroBannerItem%', '')
                        let modifiedFragment
                        if (fragment.includes('Orchard_EasySoftwareHeroBannerContainer')) {
                            const heroBannerQuery = baseQueryForWidget
                                .replace(/%baseSectionQueryContentType%/g, ``)
                                .replace(/%widgetFragmentsPlaceholder%/g, heroBannerContainerFragment)
                                .replace(/%pathCondition%/g, `(first: 1,${isPreview ? " status: LATEST," : ""} where: {path: "${path}"})`)

                            const heroBannerResult = await puxGraphqlHelper<PageType>(heroBannerQuery, { errorMetadata: { message: `Error while running GraphQL query for widgetFragment ${widgetFragment}` } })

                            modifiedFragment = getHeroBannerItem(fragment, heroBannerResult)
                        }

                        const widgetQuery = baseQueryForWidget
                            .replace(/%baseSectionQueryContentType%/g, ``)
                            .replace(/%widgetFragmentsPlaceholder%/g, modifiedFragment ?? fragment)
                            .replace(/%pathCondition%/g, `(first: 1,${isPreview ? " status: LATEST," : ""} where: {path: "${path}"})`)

                        const widgetNode = await puxGraphqlHelper<PageType>(widgetQuery, {
                            errorMetadata:
                            {
                                message: `Error while running GraphQL query for widgetFragment ${widgetFragment}`,
                                metadata: `Query: ${widgetQuery}`
                            }
                        })

                        if (widgetNode) {
                            widgetNode[documentTypeCamelCase][0][widgetZoneName].widgets
                                .filter((section) => section.contentItemId === sectionID)[0]
                                .flow.widgets.filter((widget) => Object.keys(widget)?.length > 0)
                                .map(async (widget: WidgetData) => {
                                    // PuxRepeater - begin
                                    if (
                                        widget.contentType === `PuxSimpleRepeater` &&
                                        widget.puxSimpleFilter
                                    ) {
                                        const repWid = { ...await getRepeaterData(widget) }
                                        sectionObject.flow.widgets.push(repWid as WidgetData)
                                    } else if (widget.contentType === `PuxFormContainer`) {
                                        const formId = widget.formContainerSelector.contentItemIds[0] ?? undefined
                                        if (formId) {
                                            const formWidgetData = await getFormWidgetFromFormContainer(formId)
                                            sectionObject.flow.widgets.push({
                                                ...formWidgetData,
                                                metadata: { ...widget.metadata },
                                                puxWidgetProperties: { ...widget.puxWidgetProperties },
                                                puxWidgetAnimation: { ...widget.puxWidgetAnimation },
                                                contentType: widget.contentType,
                                                contentItemId: widget.contentItemId,
                                                mautic: {
                                                    formContainerMauticID: widget.formContainerMauticID,
                                                    formContainerSource: widget.formContainerSource,
                                                    formContainerSubmissionID: widget.formContainerSubmissionID,
                                                }
                                            })
                                        }
                                    } else {
                                        sectionObject.flow.widgets.push(widget)
                                    }
                                    // PuxRepeater - end
                                })
                        }
                    })

                    return sectionObject
                }
            } else {
                if (isPreview === true) {
                    return new Promise(async (resolve) => {
                        resolve(sectionObject)
                    })
                }
            }
        })
    )
}


const getHeroBannerItem = (widgetFragment, orchardNode) => {
    const stringifiedNode = JSON.stringify(orchardNode)
    const widgetFragmentsArray: string[] = ['']

    if (stringifiedNode.includes('EasySoftwareHeroBannerItemPicture')) {
        widgetFragmentsArray.push(getWidgetQuery('EasySoftwareHeroBannerItemPicture'))
    }

    if (stringifiedNode.includes('EasySoftwareHeroBannerItemVideo')) {
        widgetFragmentsArray.push(getWidgetQuery('EasySoftwareHeroBannerItemVideo'))
    }

    if (stringifiedNode.includes('EasySoftwareHeroBannerItemText')) {
        widgetFragmentsArray.push(getWidgetQuery('EasySoftwareHeroBannerItemText'))
    }

    if (stringifiedNode.includes('EasySoftwareHeroBannerItemAnimation')) {
        widgetFragmentsArray.push(getWidgetQuery('EasySoftwareHeroBannerItemAnimation'))
    }
    return widgetFragment.replace('%HeroBannerItem%', widgetFragmentsArray.join(' '))
}